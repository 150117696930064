<template>
  <div id="app" class="page" v-bind:class="$store.state.app.pageClass" @click="closeAllDropdowns($event)">

    <div class="navbar">

      <router-link class="navbar__logo" :to="{ name: 'Cabinet' }">
        <img src="../assets/logo.png">
      </router-link>

      <nav class="navbar__top-menu top-menu top-menu--user">
        <button class="top-menu__mobile-handler" @click.stop.prevent="toggleMenu($event)" :class="{ 'top-menu__mobile-handler--active' : $store.state.app.showMenu }"><font-awesome-icon icon="bars" /></button>

        <div class="top-menu__container" :class="{ 'top-menu__container--show' : $store.state.app.showMenu }" @click="closeMenu()">
          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Authorization' }" v-if="$store.state.user.authenticated !== true || $store.state.user.role === 'guest'">Войти в Личный кабинет</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" exact :to="{ name: 'Cabinet' }" v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'">Добро пожаловать</router-link>

          <router-link v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'" class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'Contacts' }">Контакты</router-link>
        </div>

      </nav>

      <nav class="navbar__top-menu top-menu top-menu--admin" v-if="$store.state.user.authenticated === true && ['manager', 'admin'].includes($store.state.user.role)">
        <button class="top-menu__mobile-handler" @click.stop.prevent="toggleAdminMenu()" :class="{ 'top-menu__mobile-handler--active' : $store.state.app.showAdminMenu }"><font-awesome-icon icon="user-cog" /></button>

        <div class="top-menu__container" :class="{ 'top-menu__container--show' : $store.state.app.showAdminMenu }" @click="closeAdminMenu()">
          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminUsersList', query: { page: 1 } }">Список пользователей</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminOrdersList' }">Список заказов</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminOrdersStatistics' }">Статистика заказов</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTasks' }">Управление заданиями</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTransactions' }">Транзакции</router-link>

          <router-link class="top-menu__link" active-class="top-menu__link--active" :to="{ name: 'AdminTransactionsUpload' }">Загрузка транзакций</router-link>
        </div>

      </nav>

      <nav class="navbar__cart" v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'">

        <div class="navbar__balance" v-if="$store.state.user.balance !== false">
          <strong>{{ $store.state.user.balance }}</strong> баллов
        </div>

        <Tasks />

        <router-link v-if="$store.state.cart.items.length > 0" class="navbar__cart-link" active-class="navbar__cart-link--active" :to="{ name: 'Order' }">Оформить заказ</router-link>

      </nav>

      <button v-if="$store.state.user.authenticated === true && $store.state.user.role !== 'guest'" class="button button--red navbar__logout" @click.prevent="logout()">Выйти&nbsp;&rarr;</button>
    </div>

    <router-view/>

    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="footer__phone">
              Телефон службы поддержки: <a :href="'tel:' + supportPhone">{{ supportPhone }}</a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="footer__email">
              Электронная почта: <a :href="'mailto:' + supportEmail">{{ supportEmail }}</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { supportPhones, supportEmails, workingHours } from 'base/modules/constants'

import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'

import 'base/assets/common.css'
import '../assets/common.css'
import '../assets/fonts.css'

import utils from 'base/modules/utils'
import Tasks from 'base/components/Tasks'

const storeModule = {
  namespaced: true,
  state: {
    pageClass: '',
    showMenu: false,
    showAdminMenu: false,
    showTasks: false
  },
  mutations: {
    updatePageClass: function (state, className) {
      state.pageClass = className
    },
    toggleMenu: function (state) {
      state.showMenu = !state.showMenu
    },
    closeMenu: function (state) {
      state.showMenu = false
    },
    toggleAdminMenu: function (state) {
      state.showAdminMenu = !state.showAdminMenu
    },
    closeAdminMenu: function (state) {
      state.showAdminMenu = false
    },
    toggleTasks: function (state) {
      state.showTasks = !state.showTasks
    },
    closeTasks: function (state) {
      state.showTasks = false
    }
  },
  actions: {
    toggleMenu: function ({ commit }) {
      commit('toggleMenu')
    },
    closeMenu: function ({ commit }) {
      commit('closeMenu')
    },
    toggleAdminMenu: function ({ commit }) {
      commit('toggleAdminMenu')
    },
    closeAdminMenu: function ({ commit }) {
      commit('closeAdminMenu')
    },
    toggleTasks: function ({ commit }) {
      commit('toggleTasks')
    },
    closeTasks: function ({ commit }) {
      commit('closeTasks')
    }
  }
}

export default {
  name: 'App',
  components: {
    Tasks
  },
  computed: {
    ...mapGetters({
      region: 'main/region',
    }),
    supportPhone () {
      return supportPhones[this.region]
    },
    supportEmail () {
      return supportEmails[this.region]
    },
    supportWorkingHours () {
      return workingHours[this.region]
    }
  },
  methods: {
    closeAllDropdowns (e) {
      this.$store.dispatch('app/closeAdminMenu')
      this.$store.dispatch('app/closeMenu')
      this.$store.dispatch('app/closeTasks')
    },
    toggleMenu (e) {
      this.$store.dispatch('app/closeAdminMenu')
      this.$store.dispatch('app/closeTasks')
      this.$store.dispatch('app/toggleMenu')
    },
    closeMenu () {
      this.$store.dispatch('app/closeMenu')
    },
    toggleAdminMenu () {
      this.$store.dispatch('app/closeMenu')
      this.$store.dispatch('app/closeTasks')
      this.$store.dispatch('app/toggleAdminMenu')
    },
    closeAdminMenu () {
      this.$store.dispatch('app/closeAdminMenu')
    },
    logout () {
      this.$store.dispatch('user/logout').then(() => {
        this.$root.checkPermissions()
        this.$router.push('/').catch(()=>{})
      })
    }
  },
  created: function () {
    this.$store.registerModule('app', storeModule)

    utils.showCookieMessage()
  }
}
</script>

<style scoped>
  .navbar {
    background: #f2f2f2;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
  }

  @media (min-width: 768px) {
    .navbar {
      flex-wrap: nowrap;
      height: 42px;
    }
  }

  .navbar__logo {
    padding: 4px 10px;
  }

  .navbar__logo img {
    height: auto;
    width: 89px;
  }

  .top-menu {
    /* margin-right: auto; */
  }

  @media (min-width: 768px) {
    .top-menu--user {
      padding-left: 10px;
    }
  }

  .top-menu__container {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 42px;
    background-color: #fff;
    z-index: 20;
    border-bottom: 3px solid #ddd;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08);
  }

  @media (min-width: 992px) {
    .top-menu--user .top-menu__container {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      height: 100%;
      position: static;
      background: none;
      border: none;
      box-shadow: none;
    }
    .top-menu--admin .top-menu__container {
      left: auto;
      right: auto;
      width: 260px;
      box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.15);
    }
    .navbar {
      justify-content: unset;
    }
  }

  .top-menu__container--show {
    display: block;
  }

  .top-menu__mobile-handler {
    color: #444;
    white-space: nowrap;
    padding: 0 0.7em;
    height: 100%;
    font-size: 14px;
    display: inline-block;
    border: none;
    cursor: pointer;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: color 0.2s, background-color 0.2s;
  }

  .top-menu__mobile-handler--active {
    color: #333;
    background-color: #fff;
  }

  .top-menu__mobile-handler:focus {
    outline: none;
  }

  @media (min-width: 400px) {
    .top-menu__mobile-handler {
      padding: 0 1em;
    }
  }

  @media (min-width: 992px) {
    .top-menu--user .top-menu__mobile-handler {
      display: none;
    }
  }

  .top-menu__link {
    color: #6b6b6d;
    white-space: nowrap;
    padding: 14px 15px;
    font-size: 15px;
    display: block;
  }

  .top-menu__link + .top-menu__link {
    border-top: 1px solid #ddd;
  }

  @media (min-width: 992px) {
    .top-menu--user .top-menu__link {
      display: inline-block;
      padding: 11px 9px;
      letter-spacing: -0.1em;
      font-size: 13px;
      transition: background-color 0.2s;
    }

    .top-menu--user .top-menu__link + .top-menu__link {
      border-top: none;
    }
  }

  @media (min-width: 992px) {
    .top-menu--user .top-menu__link {
      letter-spacing: 0;
    }
  }

  .top-menu__link:hover {
    text-decoration: none;
    background-color: #fff;
  }

  .top-menu__link--active {
    color: #000;
    background-color: #fff;
    position: relative;
  }

  .top-menu__link--active:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 3px;
    background: var(--accent-color);
  }

  .navbar__cart {
    order: 10;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    background-color: #fafafa;
  }

  @media (min-width: 768px) {
    .navbar__cart {
      order: unset;
      width: auto;
      background-color: transparent;
      margin-left: auto;
    }
  }

  .navbar__balance {
    color: #333;
    font-size: 13px;
    line-height: 36px;
    padding: 0 12px;
    white-space: nowrap;
    letter-spacing: -1px;
    display: inline-block;
    transition: color 0.3s, background-color 0.3s;
  }

  @media (min-width: 768px) {
    .navbar__balance {
      padding: 0 10px;
      margin-right: 0;
      letter-spacing: 0;
    }
  }

  @media (min-width: 992px) {
    .navbar__balance {
      padding: 0 14px;
    }
  }

  .navbar__balance strong {
    font-size: 22px;
    color: var(--main-color);
    font-weight: 500;
  }

  .navbar__cart-link {
    background: var(--main-color);
    color: #fff;
    padding: 0 10px;
    line-height: 42px;
    font-size: 13px;
    margin-left: auto;
    display: inline-block;
    white-space: nowrap;
    transition: color 0.3s, background-color 0.3s;
  }

  @media (min-width: 480px) {
    .navbar__cart-link {
      font-size: 14px;
      padding: 0 14px;
    }
  }

  @media (min-width: 768px) {
    .navbar__cart-link {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (min-width: 992px) {
    .navbar__cart-link {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .navbar__cart-link:hover {
    color: var(--main-color);
    background-color: #fff;
    text-decoration: none;
  }

  .navbar__logout {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 13px;
    line-height: 1;
    margin-left: auto;
  }

  @media (min-width: 480px) {
    .navbar__logout {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) {
    .navbar__logout {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
    }
  }

  @media (min-width: 992px) {
    .navbar__logout {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .footer {
    background: #f2f2f2;
    padding: 10px 0;
    margin-top: auto;
    font-size: 13px;
  }

  .page--auth .footer {
    background: rgba(255, 255, 255, 0.7);
  }

  @media (min-width: 768px) {
    .footer {
      padding: 12px 0;
      font-size: 15px;
    }
  }

  .footer a {
    color: var(--main-color);
  }

  @media (min-width: 768px) {
    .footer__email {
      text-align: right;
    }
  }
</style>
